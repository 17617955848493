const $ = require('jquery');

const { tabletPx } = require('../../scss/_variables.scss').default;


const matchMediaTablet = window.matchMedia(`(min-width: ${tabletPx})`);

class Header {
    constructor (node) {
        const $node = $(node);
        this.$node = $node;

        this.$navToggleNode = this.$node.find('[data-ref~="toggle"]');
        this.$itemNodes = this.$node.find('[data-ref~="item"]');
        this.$itemToggleNodes = this.$node.find('[data-ref~="item-toggle"]');
        this.$originalOpenItemNode = this.$itemNodes.filter('.is-open');

        this.$navToggleNode.on('click', this.handleToggleClick.bind(this));
        this.$itemToggleNodes.on('click', this.handleItemToggleClick.bind(this));
        this.$itemNodes.on('mouseenter focusin', this.handleItemActivate.bind(this));

        this._handleDocumentKeyDown = this.handleDocumentKeyDown.bind(this);
    }

    handleDocumentKeyDown (event) {
        if (event.key === 'Escape') {
            // Close nav
            this.$navToggleNode.trigger('click');
        }
    }

    handleToggleClick () {
        if (!this.$node.hasClass('is-open')) {
            // Opening nav

            // Listen for escape key
            $(document).on('keydown', this._handleDocumentKeyDown);
        } else {
            // Closing nav

            // Stop listening for escape key
            $(document).off('keydown', this._handleDocumentKeyDown);

            // Reopen original item node
            this.$itemNodes.removeClass('is-open');
            this.$originalOpenItemNode.addClass('is-open');
        }
        this.$node.toggleClass('is-open');
    }

    handleItemToggleClick (event) {
        const $toggleNode = $(event.currentTarget);
        const $itemNode = $toggleNode.closest(this.$itemNodes);

        if ($itemNode.hasClass('is-open')) {
            $itemNode.removeClass('is-open');
        } else {
            this.$itemNodes.removeClass('is-open');
            $itemNode.addClass('is-open');
        }
    }

    handleItemActivate (event) {
        if (matchMediaTablet.matches) {
            const $itemNode = $(event.currentTarget);

            this.$itemNodes.removeClass('is-open');
            $itemNode.addClass('is-open');
        }
    }
}

module.exports = Header;
