const $ = require('jquery');
const Cookies = require('js-cookie');
const md5 = require('md5');
const Url = require('url-parse');
const URLSearchParams = require('url-search-params');

const { cookieConsentGiven } = require('../utils');
const Modal = require('./modal');


class NewsletterForm {
    constructor (node) {
        const $node = $(node);
        this.$node = $node;

        this.$formNode = this.$node.find('[data-ref="form"]');
        this.$inputNode = this.$node.find('[data-ref="input"]');

        this.autoActivate = this.$node.attr('data-auto-activate') === 'true';
        this.autoActivateTimeout = 5000;
        this.seenCookieName = `newsletter_auto_seen_${md5(this.$formNode.attr('action'))}`;

        if (this.autoActivate) {
            this.autoActivateTimer = window.setTimeout(
                this.autoActivateIfAppropriate.bind(this),
                this.autoActivateTimeout
            );
        }

        this.$formNode.on('submit', this.handleSubmit.bind(this));
        this.$inputNode.on('focus', () => window.clearTimeout(this.autoActivateTimer));
    }

    autoActivateIfAppropriate () {
        if (typeof Cookies.get(this.seenCookieName) === 'undefined') {
            this.handleSubmit();

            // If consented, set cookie to prevent further auto activation
            if (cookieConsentGiven('functional')) {
                // Set "seen" cookie (session lifetime only)
                Cookies.set(this.seenCookieName, 'yes', {
                    secure: (window.location.protocol === 'https')
                });
            }
        }
    }

    handleSubmit (event) {
        event && event.preventDefault();

        // Get original form target
        const target = this.$formNode.attr('action');

        // Parse it with `URL`
        const url = Url(target);

        // Parse the query string with `URLSearchParams`
        const searchParams = new URLSearchParams(url.query);

        // Add in any data from the form (i.e. the email address)
        const formData = new FormData(this.$formNode.get(0));
        for (const [key, value] of formData) {
            searchParams.set(key, value);
        }

        // Update the query property of the `URL` object
        url.query = searchParams.toString();

        // Create an iframe element targeting the URL
        const $iframe = $(`<iframe src="${url.toString()}" frameborder="0" />`);

        // Instantiate a new `Modal` containing the iframe
        new Modal($iframe.get(0)).open();
    }
}

module.exports = NewsletterForm;
